import cx from 'classnames'
import Link from 'next/link'
import { useEffect } from 'react'

import { useTranslate } from '~/hooks/actions'
import { audioEyeInit } from '~/services/audioeye'

import FooterLinks from './Links'
import config from '~/../site-config.js'

import footerStyles from './Footer.module.scss'

const Footer = () => {
  const translate = useTranslate()
  const { customer, legal } = config.footer

  useEffect(() => {
    audioEyeInit()
  }, [])

  return (
    <footer id="footer" className={cx('font--utility-3', footerStyles.footer)}>
      {/* Logo */}
      <h1 className={footerStyles.footerLogo} aria-label="logo">
        <Link href="/">
          <a itemProp="url" className={`icon icon--monogram`}></a>
        </Link>
      </h1>

      {/* Stacked menus */}
      {customer.length && <FooterLinks menu={customer} label="customer" />}
      {legal.length && (
        <FooterLinks
          menu={legal}
          label="legal"
          className={footerStyles.footerLinks_legal}
        />
      )}

      {/* Copyright */}
      <section className={footerStyles.footerCopyright} aria-label="copyright">
        {translate('footer.copyright.title')}
      </section>
    </footer>
  )
}

export default Footer
