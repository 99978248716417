/* eslint-disable react/no-unknown-property */
import Link from 'next/link'
import linksStyles from './FooterLinks.module.scss'

import { useTranslate } from '~/hooks/actions'

const FooterLinks = ({ menu, label, className }) => {
  const translate = useTranslate()
  
  return (
    <ul
      className={`${linksStyles.list} ${className}`}
      role="menu"
      aria-label={label}>
      {menu.map((item, index) => {
        const { name, slug, query, isCky, target } = item
        return (
          <li className={linksStyles.list_item} key={index}>
            <Link href={query ? { pathname: slug, query: query } : slug}>
              <a className={isCky ? 'cky-banner-element' : ''} target={target}>
                {translate(`footer.customer_links.${name.toLowerCase()}`)}
              </a>
            </Link>
          </li>
        )
      })}
    </ul>
  )
}
export default FooterLinks
